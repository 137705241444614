@font-face {
  font-family: 'GoogleSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Regular/GoogleSans-Regular.ttf');
  src: local('Google Sans'), local('GoogleSans'),
    url('../assets/fonts/Regular/GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Regular/GoogleSans-Regular.woff') format('woff'),
    url('../assets/fonts/Regular/GoogleSans-Regular.ttf') format('truetype'),
    url('../assets/fonts/Regular/GoogleSans-Regular.svg#GoogleSans') format('svg');
}

@font-face {
  font-family: 'GoogleSans-Bold';
  font-style: bold;
  font-weight: 700;
  src: url('../assets/fonts/Bold/GoogleSans-Bold.ttf');
  src: local('Google Sans'), local('GoogleSans'),
    url('../assets/fonts/Bold/GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Bold/GoogleSans-Bold.woff') format('woff'),
    url('../assets/fonts/Bold/GoogleSans-Bold.ttf') format('truetype'),
    url('../assets/fonts/Bold/GoogleSans-Bold.svg#GoogleSans') format('svg');
}
